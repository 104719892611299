<template>
  <div class="contenedor-home">
    <div class="seccion-video">
      <video
        src="https://res.cloudinary.com/dkkwevd1b/video/upload/ba0ylriulo2njmy2zgjp"
        autoplay
        muted
        class="video"
      ></video>
      <div class="clima">
        <img :src="icono" alt="" />
        <div
          style="
            margin-top: 16px;
            margin-bottom: 32px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
          "
        >
          <h1 style="color: #fff">{{ temperatura }}°</h1>
          <h6 style="color: #fff">{{ tiempo }}</h6>
        </div>
        <h6 style="color: #fff">{{ fecha }}</h6>
      </div>
    </div>
    <TarjetasComponent />

    <ImagenComponent />
    <PromocionesComponent />
    <CardEcoComponent />

    <TituloComponent titulo="ÚLTIMAS NOVEDADES" />

    <div class="carouselNoticias">
      <CarouselComponent />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TarjetasComponent from "@/components/Buscador/TarjetasComponent.vue";
import ImagenComponent from "@/components/Imagen/ImagenComponent.vue";
import PromocionesComponent from "@/components/Promociones/PromocionesComponent.vue";
import CardEcoComponent from "@/components/Ecologico/CardEcoComponent.vue";
import CarouselComponent from "@/components/Carousel/CarouselComponent.vue";
import TituloComponent from "@/components/Reutilizar/TituloComponent.vue";
import { useHead } from "@vueuse/head";

useHead({
  title: "Inicio - Municipalidad de Luque",
  meta: [
    {
      name: "description",
      content:
        "Bienvenidos al sitio oficial de la Municipalidad de Luque. Encuentra información sobre trámites, servicios, noticias y eventos para nuestra comunidad.",
    },
    { name: "viewport", content: "width=device-width, initial-scale=1" },
  ],
});

export default {
  name: "HomeView",
  components: {
    TarjetasComponent,
    ImagenComponent,
    PromocionesComponent,
    CardEcoComponent,
    CarouselComponent,
    TituloComponent,
  },
  data() {
    return {
      temperatura: "",
      tiempo: "",
      icono: "",
      fecha: "",
    };
  },
  methods: {
    getTiempo() {
      axios
        .get(
          "https://api.tutiempo.net/json/?lan=es&apid=a5GX44aXXaahzCV&ll=-31.6476686,-63.3444"
        )
        .then((response) => {
          // console.log(response);
          this.temperatura = response.data.hour_hour.hour1.temperature;
          this.tiempo = response.data.hour_hour.hour1.text;
          let icon = response.data.hour_hour.hour1.icon;
          // let dia = new Date();
          // this.dia = dia.getDate();
          // this.getDia(dia.getDay());
          // this.getMes(dia.getMonth());
          // this.text = asd.split(" ");
          // this.text = this.text[0].toUpperCase();
          // this.text = asd.toUpperCase();

          this.icono = `https://v5i.tutiempo.net/wi/02/30/${icon}.png`;
        });
    },
  },
  created() {
    window.scrollTo(0, 0);
    this.getTiempo();
    let dia = new Date().getDate();
    let mes = new Date().getMonth() + 1;
    this.fecha = `${dia}/${mes}`;
  },
};
</script>
<style scoped>
.video {
  width: 100%;
  border-bottom-right-radius: 53.12px;
}
.contenedor-home {
  margin-top: 0px;
  padding-top: 100px;
}
.clima {
  z-index: 3;
  height: 250px;
  width: 100px;
  background: linear-gradient(
    158deg,
    rgba(245, 245, 245, 0.2) 32.29%,
    rgba(1, 153, 57, 0.2) 99.99%,
    rgba(1, 153, 57, 0.2) 100%
  );
  border-radius: 50px;
  position: absolute;
  top: 100px;
  left: 84px;
  color: #fff;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.clima h1 {
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}
.clima h6 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #fff;
}
.seccion-video {
  width: 100%;
  background: var(--bordeDegradeCompleto);
  border-bottom-right-radius: 53.12px;
  margin-bottom: 60px;
  position: relative;
}
.izquierdo {
  width: 33vw;
  height: 1px;
  background: #4b4a49;
  margin-bottom: 1%;
}

.derecho {
  height: 1px;
  width: 33vw;
  background: #4b4a49;
  margin-bottom: 1%;
}
.carouselNoticias {
  align-items: center;
  margin-top: 60px;
}
.masGestiones {
  display: none;
}

@media (max-width: 770px) {
  .seccion-video {
    width: 100%;
    background: none;
    border-bottom-right-radius: 25px;
    margin-bottom: 21px;
    position: relative;
    /* padding-top: 56px; */
  }

  .video {
    height: 100%;
    width: 100%;
    border-bottom-right-radius: 25px;
  }
  .contenedor-home {
    width: 100%;
    padding-top: 55px;
  }
  .clima {
    display: none;
  }
}
</style>
