import { createStore } from "vuex";

export default createStore({
  state: {
    categorias: {},
  },
  getters: {},
  mutations: {
    getCategorias(state, obj) {
      state.categorias = obj;
    },
  },
  actions: {
    getCategoriasAction(context, obj) {
      context.commit("getCategorias", obj);
    },
  },
  modules: {},
});
