<template>
  <div class="contenedor-imagenes">
    <img
      src="https://res.cloudinary.com/dkkwevd1b/image/upload/w_557,h_250/v1732026226/kkbnh5qg9lmrxl1c1sqs.jpg"
      alt="hombre"
      class="imagenderecha"
    />
    <div class="imagenChica"></div>
  </div>
</template>
<script>
export default {
  name: "PromocionesComponent",
};
</script>
<style scoped>
.contenedor-imagenes {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  width: 1138px;
  height: auto;
  /* margin-top: 3%; */
  gap: 62.608px;
  margin: auto;
  margin-top: 60px;
  justify-content: center;
  overflow: hidden;
}
.imagenChica {
  background-image: url("@/../public/images/imagen-chica.png");
  width: 557px;
  height: 250px;
  border-radius: 30.356px;
  object-fit: fill;
  background-size: cover;
}
.imagenderecha {
  width: 557px;
  height: 250px;
  border-radius: 30.356px;
  object-fit: fill;
  height: -webkit-fill-available;
}
/*.imagenIzquierda {
  width: 557px;
  height: 250px;
  border-radius: 30.356px;
  object-fit: fill;
}*/
@media (max-width: 1200px) {
  .contenedor-imagenes {
    width: 86%;
    height: auto;
    flex-direction: column;
  }
  .imagenderecha {
    width: 100%;
    height: 100%;
    border-radius: 30.356px;
  }
  .imagenIzquierda {
    width: 100%;
    height: 100%;
    border-radius: 30.356px;
  }
}
@media (max-width: 770px) {
  .contenedor-imagenes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 325px;
    height: auto;
    /* justify-content: space-between; */
    /* margin-top: 3%; */
    gap: 21px;
    margin: auto;
    margin-top: 21px;
    justify-content: center;
    overflow: hidden;
  }
  .imagenderecha {
    width: 100%;
    height: 132px;
    border-radius: 15px;
    object-fit: fill;
  }
  .imagenChica {
    width: 100%;
    height: 132px;
    object-fit: fill;
    border-radius: 15px;
  }
}
</style>
